<template>
  <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.75 0C5.82187 0 5.0625 0.759375 5.0625 1.6875V25.3125C5.0625 26.2406 5.82187 27 6.75 27H23.625C24.5531 27 25.3125 26.2406 25.3125 25.3125V6.75L18.5625 0H6.75Z" fill="#E2E5E7"/>
    <path d="M20.25 6.75H25.3125L18.5625 0V5.0625C18.5625 5.99062 19.3219 6.75 20.25 6.75Z" fill="#B0B7BD"/>
    <path d="M25.3125 11.8125L20.25 6.75H25.3125V11.8125Z" fill="#CAD1D8"/>
    <path d="M21.9375 21.9375C21.9375 22.4016 21.5578 22.7812 21.0938 22.7812H2.53125C2.06719 22.7812 1.6875 22.4016 1.6875 21.9375V13.5C1.6875 13.0359 2.06719 12.6562 2.53125 12.6562H21.0938C21.5578 12.6562 21.9375 13.0359 21.9375 13.5V21.9375Z" fill="#84BD5A"/>
    <path d="M7.61153 17.2015L8.78519 15.7308C9.13619 15.2718 9.81794 15.8582 9.43319 16.297C9.02903 16.7762 8.60378 17.296 8.19878 17.8284L9.57494 19.542C9.94619 20.0483 9.20453 20.5334 8.84003 20.0339L7.59803 18.4418L6.37712 20.0685C6.03287 20.5604 5.27012 20.0078 5.65487 19.5488L7.01078 17.8284C6.58553 17.2951 6.17378 16.7762 5.76287 16.297C5.33846 15.7907 6.12737 15.2718 6.43787 15.7451L7.61153 17.2015Z" fill="white"/>
    <path d="M10.4077 15.9867C10.4077 15.764 10.5967 15.575 10.833 15.575C11.049 15.575 11.2312 15.7648 11.2312 15.9867V19.3668H13.0664C13.727 19.3668 13.7414 20.2502 13.0664 20.2502H10.8338C10.5976 20.2502 10.4086 20.0823 10.4086 19.8393V15.9867H10.4077Z" fill="white"/>
    <path d="M14.3455 16.594C14.5008 15.2845 16.4769 15.0491 17.4084 15.7646C17.8674 16.135 17.3814 16.7223 16.9764 16.4253C16.4769 16.1089 15.3437 15.9604 15.202 16.6607C15.0197 17.7677 17.9543 17.134 17.907 18.9287C17.8598 20.6424 15.3775 20.682 14.446 19.9134C14.2224 19.7311 14.23 19.4349 14.3515 19.2519C14.527 19.0772 14.7219 19.0164 14.9522 19.2054C15.5049 19.5826 16.9291 19.8661 17.0295 18.9076C16.9426 17.9103 14.1363 18.5102 14.3455 16.594Z" fill="white"/>
    <path d="M21.0938 22.7812H5.0625V23.625H21.0938C21.5578 23.625 21.9375 23.2453 21.9375 22.7812V21.9375C21.9375 22.4016 21.5578 22.7812 21.0938 22.7812Z" fill="#CAD1D8"/>
  </svg>
</template>